<template>
    <main class="w-100 py-25 page_bg_grey blank-aside-js min-h-100vh" :class = "(isOpenAside=='true')?'blank-aside':''">

        <div v-if="!transactionStatus || transactionStatus <= 1 || transactionStatus == 5" class="text-center">
            In progress...
        </div>

        <div v-else-if="transactionStatus == 3">

            <section v-if="transactionReceiptData" class="success-section fs16">
                <div>
                    <p class="mb-5 fs20">{{ $t("Invitation accepted") }}</p>
                    <p class="mb-0">
                        <span class="font-weight-bold">{{ $t("Date") }}:</span> {{ transactionReceiptData.transDateTime | formatDate }}
                    </p>
                    <p class="mb-0">
                        <span class="font-weight-bold">{{ $t("Place") }}:</span> {{ transactionReceiptData.user_address }}
                    </p>
                    <p class="mb-0">
                        <span class="font-weight-bold">{{ $t("Agent") }}:</span> {{ transactionReceiptData.business_name }}
                    </p>
                    <p class="mb-0">
                        <span class="font-weight-bold">{{ $t("Phone") }}:</span> {{ transactionReceiptData.targetPhone }}
                    </p>
                    <p class="mb-0" v-if="transactionReceiptData.card_number">
                        <span class="font-weight-bold">{{ $t("Card Number") }}:</span> {{ transactionReceiptData.card_number }}
                    </p>
                    <p class="mb-0" v-if="transactionReceiptData.card_code">
                        <span class="font-weight-bold">{{ $t("Card Code") }}:</span> {{ transactionReceiptData.card_code }}
                    </p>
                </div>

                <hr class="my-20">

                <div class="d-flex justify-content-between align-items-center mb-5">
                    <h2 class="font-weight-bold">{{ $t("Successful Order") }} {{ ' ' + transactionId + ' ' }}</h2>
                </div>

                <div class="bg-white">
                    <div class="d-flex font-weight-bold bd-b-1 py-5">
                        <div class="w-60 px-10">
                            {{ $t("Product") }}
                        </div>
                        <div class="w-15 text-center min-w-100px">
                            {{ $t("Quantity") }}
                        </div>
                        <div class="w-25 text-center min-w-100px">
                            {{ $t("Price") }}
                        </div>
                    </div>
                    <div class="d-flex bd-b-1 py-10">
                        <div class="w-60 px-10">
                            {{ transactionReceiptData.product }}
                        </div>
                        <div class="w-15 text-center min-w-100px">
                            1
                        </div>
                        <div class="w-25 text-center min-w-100px">
                            {{ transactionReceiptData.price }} ₪
                        </div>
                    </div>
                    <div class="py-10 d-flex justify-content-end">
                        <div class="d-flex flex-wrap w-50">
                            <div class="w-50 px-2 mb-5">
                                {{ $t("Total") }}
                            </div>
                            <div class="w-50 px-2 mb-5 text-center">
                                {{ priceWithoutVAT }} ₪
                            </div>
                            <div class="w-50 px-2 mb-5">
                                {{ $t("VAT") }}
                            </div>
                            <div class="w-50 px-2 mb-5 text-center">
                                {{ priceVAT }} ₪
                            </div>
                            <div class="w-50 px-2 mb-5 fs16">
                                {{ $t("Total payment including VAT") }}
                            </div>
                            <div class="w-50 px-2 mb-5 fs16 font-weight-bold text-center">
                                {{ price }} ₪
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div v-else-if="transactionStatus == 4 || transactionStatus == 2" class="text-center">
            {{ transactionDataStatusMessage ? transactionDataStatusMessage : $t("Payment failed, please contact support or try again later") }}
        </div>

        <loading :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </main>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import appConfig from '../../appConfig'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        components: {
            Loading
        },
        name: 'GamePaymentResult',
        data: () => {
            return {
                checkTransactionStatusInterval: null,
                loading: false
            }
        },
        mounted() {
        },
        destroyed() {
            this.clearCheckTransactionStatusInterval()
        },
        computed: {
            ...mapState({
                transactions_receipts: state => state.gift_card_payment.transactions_receipts,
                current_transaction_supplier_id: state => state.gift_card_payment.current_transaction_supplier_id,
                current_transaction_product_id: state => state.gift_card_payment.current_transaction_product_id,
                supplier_items: state => state.supplier.supplier_items,
                suppliers: state => state.supplier.suppliers,
                suppliers_products: state => state.supplier.suppliers_products,
                products: state => state.product.products,
                transactions_statuses: state => state.gift_card_payment.transactions_statuses,
                getTransactionReceiptInProgress: state => state.gift_card_payment.getTransactionReceiptInProgress,
            }),
            transactionId() {
                return this.$route.params.transaction_id
            },
            transactionSupplierId() {
                return this.current_transaction_supplier_id ? this.current_transaction_supplier_id : null
            },
            transactionProductId() {
                return this.current_transaction_product_id ? this.current_transaction_product_id : null
            },
            transactionReceiptData() {
                let transactionReceiptData = null;
                if (this.transactions_receipts && this.transactionId in this.transactions_receipts) {
                    transactionReceiptData = {...this.transactions_receipts[this.transactionId]}
                }

                return transactionReceiptData ? transactionReceiptData : null
            },
            transactionSupplierData() {
                let supplierDataFromSuppliersItemsData = null;
                if (this.supplier_items) {
                    for (let supplier_type in this.supplier_items) {
                        for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                            if (suppliers_type_item.id == this.transactionSupplierId) {
                                supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                                break;
                            }
                        }
                    }
                }

                let supplierDataFromSuppliersData = null;
                if (this.suppliers && this.transactionSupplierId in this.suppliers) {
                    supplierDataFromSuppliersData = {...this.suppliers[this.transactionSupplierId]}
                }

                let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
                return supplierData ? supplierData : null
            },
            transactionProductData() {
                let productDataFromSuppliersData = null;

                if (this.suppliers_products) {
                    for (let supplier_id in this.suppliers_products) {
                        for (let supplier_product_type in this.suppliers_products[supplier_id]) {
                            for (let supplier_product of this.suppliers_products[supplier_id][supplier_product_type]) {
                                if (supplier_product.hasOwnProperty('id')
                                    && supplier_product.id == this.transactionProductId) {
                                    productDataFromSuppliersData = {...supplier_product}
                                    break;
                                }
                            }
                        }
                    }
                }

                let productDataFromProductsData = null;
                if (this.products && this.transactionProductId in this.products) {
                    productDataFromProductsData = {...this.products[this.transactionProductId]}
                }

                let productData = productDataFromProductsData ? productDataFromProductsData : productDataFromSuppliersData
                return productData ? productData : null
            },
            price() {
                return this.transactionReceiptData.price ? this.formatNumber(this.transactionReceiptData.price) : ''
            },
            priceVAT() {
                return this.price ? this.formatNumber(this.price * 0.17) : 0
            },
            priceWithoutVAT() {
                return this.price && this.priceVAT ? this.formatNumber(this.price - this.priceVAT) : this.price
            },
            transactionStatusData() {
                let transactionStatusData = this.transactionId in this.transactions_statuses ? this.transactions_statuses[this.transactionId] : null

                return transactionStatusData
            },
            transactionStatus() {
                let transactionStatus = this.transactionStatusData && this.transactionStatusData.hasOwnProperty('status') ? this.transactionStatusData.status : null

                return transactionStatus
            },
            transactionDataStatus() {
                let transactionDataStatus = this.transactionStatusData && this.transactionStatusData.hasOwnProperty('transactionData') && this.transactionStatusData.transactionData.hasOwnProperty('status') ? this.transactionStatusData.transactionData.status : null

                return transactionDataStatus
            },
            transactionDataStatusMessage() {
                let transactionDataStatusMessage = this.transactionStatusData && this.transactionStatusData.hasOwnProperty('transactionData') && this.transactionStatusData.transactionData.hasOwnProperty('comment') ? this.transactionStatusData.transactionData.comment : null

                return transactionDataStatusMessage
            }
        },
        created() {
            this.startStatusCheckingProcess()
        },
        watch: {
            transactionStatus(transactionStatus) {
                if (transactionStatus == 3) {
                    this.getTransactionReceipt({'transaction_id': this.transactionId})

                    if (this.transactionProductId) {
                        this.getProduct({'product_id': this.transactionProductId})
                    }

                    if (this.transactionSupplierId) {
                        this.getSupplier({'supplier_id': this.transactionSupplierId})
                    }
                }
            },
            checkTransactionStatusInterval(checkTransactionStatusInterval) {
                let show_loading = !!checkTransactionStatusInterval || this.getTransactionReceiptInProgress

                this.loading = show_loading
            },
            getTransactionReceiptInProgress(getTransactionReceiptInProgress) {
                let show_loading = !!this.checkTransactionStatusInterval || getTransactionReceiptInProgress

                this.loading = show_loading
            }
        },
        methods: {
            ...mapActions('gift_card_payment', {
                getTransactionReceipt: 'getTransactionReceipt'
            }),
            ...mapActions('product', {
                getProduct: 'getProduct'
            }),
            ...mapActions('supplier', {
                getSupplier: 'getSupplier'
            }),
            ...mapActions('gift_card_payment', {
                checkTransactionStatus: 'checkTransactionStatus'
            }),
            getApiURL() {
                return appConfig.apiUrl
            },
            printReceipt() {
                window.print();
            },
            formatNumber(number) {
                let numberFormatObject = new Intl.NumberFormat('en-US')
                return numberFormatObject.format(number)
            },
            startStatusCheckingProcess() {
                let obj = this
                if (obj.checkTransactionStatusInterval || !obj.transactionId
                    || obj.transactionStatus && obj.transactionStatus > 1 && obj.transactionStatus != 5) {
                    return
                }

                obj.checkTransactionStatusInterval = setInterval(async function () {
                    await obj.checkTransactionStatus({'transaction_id': obj.transactionId, 'phoneNumber': obj.phoneNumber}).then(
                        result_data => {
                            if (obj.transactionStatus > 1 && obj.transactionStatus != 5) {
                                obj.clearCheckTransactionStatusInterval()
                            }
                        },
                        error => {
                            obj.clearCheckTransactionStatusInterval()
                            alert('Error!')
                        }
                    )
                        .catch(error => {
                            obj.clearCheckTransactionStatusInterval()
                            alert('Error!')
                        })

                }, 3000);
            },
            clearCheckTransactionStatusInterval() {
                clearInterval(this.checkTransactionStatusInterval)
                this.checkTransactionStatusInterval = null
            }
        },
        updated() {
        },
        beforeRouteLeave(to, from, next) {
            if (this.checkTransactionStatusInterval) {
                const answer = window.confirm('Do you really want to leave? Payment in progress, you may lose progress!')
                if (answer) {
                    next()
                } else {
                    next(false)
                }
            } else {
                next()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .success-section {
        padding: 0 10px;
        max-width: 932px;
        width: 100%;
        margin: 0 auto;
    }

    .min-w-100px {
        min-width: 100px;
    }
</style>